<template>
  <div id="camera">
    <input v-if="isFileSearcher" type="file" ref="fileInput" accept="image/*" @change="handleFileChange" style="display: none" />
    <div class="button_container">
      <Button
          style="padding: 5px"
          class="p-col p-button-text p-button-rounded"
          :label="!isFileSearcher && showLabel ? 'Prendre une photo' : ''"
          icon="pi pi-camera"
          iconPos="right"
          @click="startCamera"
      />
      <span v-if="isFileSearcher" class="p-m-2">/</span>
      <Button
          v-if="isFileSearcher"
          style="padding: 5px"
          class="p-col p-button-text p-button-rounded"
          :label="!isFileSearcher && showLabel ? 'Ajouter une image' : ''"
          icon="pi pi-folder"
          iconPos="right"
          @click="searchFile"
      />
    </div>
    <Sidebar v-model:visible="visible" position="full" @hide="stopCamera">
      <div class="container">
        <div v-if="!photoTaken">
          <video ref="video" autoplay class="video"></video>
          <div class="button-container">
            <Button @click="switchCamera" class="switch-button p-button-text" icon="pi pi-sync" />
            <Button @click="takePhoto" icon="pi pi-camera" />
          </div>
        </div>
        <div v-else >
          <img :src="photo" alt="Photo Preview" class="photo" style="max-height: 20%;" />
          <div class="button-container">
            <Button @click="retakePhoto" icon="pi pi-replay" />
            <Button @click="approvePhoto" class="p-button-success" icon="pi pi-check" />
          </div>
          <div v-if="showCommentSection" class="comment-section">
            <TextArea class="p-fluid"  v-model="comment" placeholder="Ajoutez un commentaire ..." autoResize  />
            <Button @click="savePhoto" :loading="sendingImage" label="Enregistrer" icon="pi pi-save" />
          </div>
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue';
import Sidebar from "primevue/sidebar"
import Button from "primevue/button"
import TextArea from "primevue/textarea"
import axios from 'axios';
import { useUsersStore } from '@/store/users';
import IImage from '@/models/images/Image'

const video = ref(null);
const canvas = ref(null);
const photo = ref(null);
const photoTaken = ref(false);
const showCommentSection = ref(false);
const comment = ref('');
const visible = ref(false);
const cameraStarted = ref(false);
let stream = null;
const usersStore = useUsersStore();

const props = defineProps(["showLabel", 'isFileSearcher']);
const emit = defineEmits();
const sendingImage = ref(false)
const currentFacingMode = ref('environment');
const fileInput = ref()

const startCamera = () => {
  visible.value = true;
  const constraints = {
    video: { facingMode: currentFacingMode.value }, // Utilise la caméra arrière si disponible
    audio: false
  };

  navigator.mediaDevices
    .getUserMedia(constraints)
    .then((mediaStream) => {
      stream = mediaStream;
      if ('srcObject' in video.value) {
        video.value.srcObject = stream;
      } else {
        video.value.src = window.URL.createObjectURL(stream);
      }
      video.value.play();
      cameraStarted.value = true;
    })
    .catch((err) => {
      console.error('An error occurred: ' + err);
      // Retenter avec la caméra frontale en cas d'erreur
      if (constraints.video.facingMode === 'environment') {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((mediaStream) => {
            stream = mediaStream;
            if ('srcObject' in video.value) {
              video.value.srcObject = stream;
            } else {
              video.value.src = window.URL.createObjectURL(stream);
            }
            video.value.play();
            cameraStarted.value = true;
          })
          .catch((err) => {
            console.error('An error occurred while using the front camera as fallback: ' + err);
          });
      }
    });
};

const stopCamera = () => {
  if (stream) {
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
    stream = null;
  }
  if (video.value) {
    video.value.srcObject = null;
  }
  cameraStarted.value = false;
};

const takePhoto = () => {
  if (!canvas.value) {
    canvas.value = document.createElement('canvas');
  }

  const context = canvas.value.getContext('2d');
  const width = video.value.videoWidth;
  const height = video.value.videoHeight;

  if (width && height) {
    canvas.value.width = width;
    canvas.value.height = height;
    context.drawImage(video.value, 0, 0, width, height);

    photo.value = canvas.value.toDataURL('image/png');
    photoTaken.value = true;
  }
};

const retakePhoto = () => {
  photoTaken.value = false;
  showCommentSection.value = false;
  comment.value = '';
  startCamera();
};

const approvePhoto = () => {
  showCommentSection.value = true;
};

const sendImage = (blob) => {
  const image = new File([blob], "image.png",{ type: "image/png" })
  const fd = new FormData();
  fd.append("image", image);
  fd.append("comment", comment.value);
  axios
    .post(process.env.VUE_APP_API_URL + "/api/images", fd, {
      headers: {
        Authorization: "Token " + usersStore.state.appUser.token,
      },
    })
    .then((res) => {
      const newmage = Object.assign(new IImage(), res.data);
      emit("addValue", newmage);
      photo.value = undefined;
      comment.value = '';
      photoTaken.value = false;
      stopCamera();
      visible.value = false;
      sendingImage.value = false
    });
}

const savePhoto = () => {
  sendingImage.value = true
  fetch(photo.value)
  .then(res => res.blob())
  .then(blob => {
    sendImage(blob)
  })
  
};

const switchCamera = () => {
  stopCamera();
  currentFacingMode.value = currentFacingMode.value === 'environment' ? 'user' : 'environment';
  startCamera();
};

const searchFile = () => {
  fileInput.value.click()
}

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    
    const img = new Image();
    const imageUrl = URL.createObjectURL(file);
    
    img.src = imageUrl
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Définir la hauteur de 640px et calculer la largeur proportionnelle
      const newHeight = 640;
      const scaleFactor = newHeight / img.height;
      const newWidth = img.width * scaleFactor;

      // Configurer la taille du canvas
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Dessiner l'image redimensionnée sur le canvas
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Convertir le canvas en Blob (image) pour l'envoyer au serveur
      canvas.toBlob((blob) => {
        sendImage(blob); // Fonction pour envoyer l'image
      }, file.type);

      URL.revokeObjectURL(imageUrl);
    };

  }
  
}
</script>

<style lang="scss">

  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    position: relative;
  }

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .centered-button {
    margin: auto;
    margin-top: -50px;
  }

  .photo {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: -50px;
  }

  .comment-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  textarea {
    width: 100%;
    height: 80px;
    margin-bottom: 10px;
  }

  .button_container {
    display: flex;
    align-items: center;
}

</style>